<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="5%">
              <v-checkbox
                v-model="item.check"
                hide-details
                class="my-0"
                @change="changeCheck()"
              ></v-checkbox>
            </th>
            <th>
              <span v-if="item.code">
                {{ item.code }}
              </span>
            </th>
            <th width="50%"></th>
            <th>{{ item.data_markups.pricePayOnline | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}</th>
            <th width="5%">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--<v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.share') }}</span>
                    </v-list-item-title>
                  </v-list-item>-->
                  <!--<v-list-item link>
                    <v-list-item-title @click="editServive">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>-->
                  <v-list-item link>
                    <v-list-item-title @click="deleteItem">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </th>
          </tr>
        </thead>
        <tbody v-if="!isLoading">
          <tr>
            <td
              colspan="2"
              class="pb-8"
              style="vertical-align: text-top;"
            >
              <v-img
                :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
                size="100"
                contain
                width="300"
                class="my-5"
                style="text-align: right !important;"
              >
                <template>
                  <v-chip
                    class="mr-2 mt-2"
                    color="primary"
                  >
                    <span v-if="checkOnRequest">On Request</span>
                    <span v-else>Free Sale</span>
                  </v-chip>
                </template>
              </v-img>

              <b :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ item.data_service.items.hotel.name }}</b>
              <br />
              <v-row no-gutters>
                <v-col
                  cols="12"
                  :md="item.data_service.items.hotel.categoria.plus ? '4' : '12'"
                >
                  <v-rating
                    background-color="default"
                    color="primary"
                    :empty-icon="icons.mdiStarOutline"
                    :full-icon="icons.mdiStar"
                    :half-icon="icons.mdiStarHalfFull"
                    length="5"
                    readonly
                    size="20"
                    dense
                    half-increments
                    :value="item.data_service.items.hotel.categoria.cant_estrellas"
                  ></v-rating>
                </v-col>
                <v-col
                  v-if="item.data_service.items.hotel.categoria.plus"
                  cols="12"
                  md="2"
                >
                  PLUS
                </v-col>
              </v-row>

              <br />
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('menu.operador')}:` }} </span>
              <span v-if="contrate">
                <span v-if="contrate.operators">
                  <span v-if="contrate.operators.length > 0">
                    {{ contrate.operators[0] }}
                  </span>
                </span>
              </span>
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('lbl.proveedor')}:` }} </span>
              <span v-if="contrate">
                <span v-if="contrate.proveedor">
                  {{ contrate.proveedor.name_comercial }}
                </span>
              </span>
              <span v-if="item.data_service.items.sources.length > 0">
                <br />
                <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> Canal: </span>
                {{ item.data_service.items.sources[0] | capitalize }}
              </span>
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
                {{ $t('lbl.typeDestinyFilters') }}:
              </span>
              {{ item.data_service.items.hotel.tipo_destino.name }}
            </td>
            <td
              style="vertical-align: top;border: 0;"
              colspan="3"
            >
              <br />
              <b>
                <span class="text-uppercase">
                  {{ $t('lbl.rentToHotels') }}
                  ({{ `${item.data_service.items.diasReservar} ${$t('lbl.nights')}` }})
                </span>
                <!--<span v-if="item.data_service.items.category_id">
                  {{ `, ${item.data_service.items.category_id.name} ${$t('lbl.withSecureInclude')}` }}
                </span>-->
              </b>
              <br />
              <br />
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.entry') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                    small
                  >
                    mdi-calendar-range
                  </v-icon>
                  {{ $moment(item.data_service.dateIn).format('DD MMM Y') }},
                  16:00
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.exit') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                    small
                  >
                    mdi-calendar-range
                  </v-icon>
                  {{ $moment(item.data_service.dateOut).format('DD MMM Y') }},
                  12:00
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-divider class="mt-2 mb-2"></v-divider>
                </v-col>
              </v-row>

              <v-row
                v-for="(rrr, indRRR) in item.data_service.items.rooms"
                :key="indRRR"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.room') }} <span v-if="item.data_service.items.rooms.length > 1">#{{ indRRR + 1 }}</span>:</b><br />
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <span v-if="itemsRooms.filter(e => e.id === rrr.room_id).length > 0">
                        {{ itemsRooms.filter(e => e.id === rrr.room_id)[0].name }}
                      </span>
                      ({{ $t('lbl.plan') }}: {{ planes.filter(e => e.id === rrr.plan)[0].siglas }})
                      <br />
                      <span v-if="rrr.tarifasSeason.filter(e => e.code === rrr.codeTarifa).length > 0">
                        {{ $t('lbl.tarif') }}:
                        {{ rrr.tarifasSeason.filter(e => e.code === rrr.codeTarifa)[0].name }} ({{ rrr.codeTarifa }})
                        <v-tooltip
                          top
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                style="cursor: pointer;"
                                class="ml-5"
                                @click="showModal(rrr)"
                              >
                                mdi-information-outline
                              </v-icon>
                            </span>
                          </template>
                          <span>
                            {{ $t('lbl.policyCancele') }}
                          </span>
                        </v-tooltip>
                      </span>

                      <!--<span
                        v-for="(room, indR) in item.data_service.items.rooms"
                        :key="indR"
                      >
                        {{ itemsRooms.filter(e => e.id === room.room_id)[0].name }}
                        ({{ $t('lbl.plan') }}: {{ planes.filter(e => e.id === room.plan)[0].siglas }})
                        <br />
                        <span v-if="room.tarifasSeason.filter(e => e.code === room.codeTarifa).length > 0">
                          {{ $t('lbl.tarif') }}:
                          {{ room.tarifasSeason.filter(e => e.code === room.codeTarifa)[0].name }} ({{ room.codeTarifa }})
                          <v-tooltip
                            top
                            color="primary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  style="cursor: pointer;"
                                  class="ml-5"
                                  @click="showModal(room)"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </span>
                            </template>
                            <span>
                              {{ $t('lbl.policyCancele') }}
                            </span>
                          </v-tooltip>
                        </span>
                        <hr v-if="indR !== item.data_service.items.rooms.length - 1" />
                      </span> -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.travels') }}:</b>
                  <br />
                  <v-row
                    no-gutters
                  >
                    <template v-if="rrr.cantAdulEdad > 0">
                      <v-col
                        v-for="adl in rrr.cantAdulEdad"
                        :key="adl"
                        cols="2"
                      >
                        <v-icon>
                          mdi-human-male
                        </v-icon>
                      </v-col>
                    </template>
                    <template v-if="rrr.cantMnrEdad > 0">
                      <v-col
                        v-for="mnr in rrr.cantMnrEdad"
                        :key="mnr"
                        cols="2"
                        style="vertical-align: bottom;"
                      >
                        <v-icon
                          size="20px"
                          class="mt-1"
                        >
                          mdi-human-child
                        </v-icon>
                      </v-col>
                    </template>
                    <template v-if="rrr.cantInfEdad > 0">
                      <v-col
                        v-for="inf in rrr.cantInfEdad"
                        :key="inf"
                        cols="2"
                        style="vertical-align: bottom;"
                      >
                        <v-icon
                          size="15px"
                          class="mt-1"
                        >
                          mdi-baby-bottle-outline
                        </v-icon>
                      </v-col>
                    </template>
                  </v-row>
                  <!--<template v-if="countInf > 0">
                    <v-icon>
                      mdi-baby-bottle-outline
                    </v-icon> x {{ countInf }}
                  </template>-->
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                            {{ $t('lbl.name') }}
                          </th>
                          <th width="15px">
                            {{ $t('lbl.edad') }}
                          </th>
                          <th width="25%">
                            ID
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pax, indRD) in rrr.paxs"
                          :key="indRD"
                        >
                          <td>
                            <span v-if="pax.name">
                              {{ pax.name }} {{ pax.apellidos }}
                            </span>
                            <span v-else>Huésped {{ indRD+1 }}</span>
                          </td>
                          <td>
                            <span v-if="pax.type === 'ADL'">
                              ADL
                            </span>
                            <span v-else-if="pax.edad">
                              {{ pax.edad }} {{ $t('lbl.years') }}
                            </span>
                          </td>
                          <td>{{ pax.noDocument }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col
                  v-if="indRRR < item.data_service.items.rooms.length - 1"
                  cols="12"
                  md="12"
                >
                  <v-divider class="mt-2 mb-2"></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="text-center"
                >
                  <span v-if="item.code && checkInfoPendient">
                    <v-chip
                      color="red"
                      text-color="white"
                      @click="editServive"
                    >
                      {{ $t('lbl.completeInfo') }}
                    </v-chip>
                  </span>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="showModalDelete"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${$t('lbl.service')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteServicio', { n: '' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalDelete = !showModalDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDeleteService"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--POLITICAS DE CANCELACION-->
    <v-dialog
      v-if="policyCancele"
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('lbl.policyCancele') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template>
          <v-row class="my-2 mx-2">
            <v-col
              cols="12"
            >
              <v-list-item-title>
                {{ policyCancele.showName }}
                <span style="font-size: 12px">
                  {{ `(${policyCancele.name} ${policyCancele.code})` }}
                </span>
              </v-list-item-title>
              <template v-if="policyCancele.slug === 'no-reembolsable'">
                <!--<span style="font-size: 12px">
                  {{ $t('lbl.titleOfert') }}
                </span>-->
              </template>
              <template v-else>
                <!--<span
                  v-if="policyCancele.dates_hasta.length > 0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.titleOfert2') }}
                  <br />
                </span>-->
                <span
                  class="mt-5"
                  style="font-size: 12px"
                >
                  <template v-if="policyCancele.dates_hasta.length > 0">
                    <b>{{ $t('lbl.beforeOf') }}</b> {{ policyCancele.dates_hasta[0] }}
                    <br />
                    {{ $t('lbl.conditionsCancelation') }}
                    <br />
                    {{ $t('lbl.cancelaFree') }} {{ policyCancele.dates_hasta[0] }}
                    <br />
                    <br />
                    <b>{{ $t('lbl.afterOf') }}</b> {{ policyCancele.dates_hasta[0] }}
                  </template>
                  <template v-else>
                    <span style="color: red">{{ $t('lbl.withPenalidad') }}</span>
                    <br />
                    <b>{{ $t('lbl.afterOf') }}</b> {{ policyCancele.dates_penalidad }}
                  </template>
                  <br />
                  {{ $t('lbl.conditionsCancelation') }}
                  <br />
                  {{ $t('lbl.cancelaPena1') }} {{ policyCancele.dates_hasta.length > 0 ? policyCancele.dates_hasta[0] : policyCancele.dates_penalidad }} {{ $t('lbl.cancelaPena2') }}
                  <br />
                  <ul>
                    <li>
                      {{ `${regimenSelect.name} (${regimenSelect.siglas})` }}
                    </li>
                  </ul>
                  <br />
                  {{ $t('lbl.penalidad') }}:
                  <br />
                  <span
                    v-for="(data, iData) in policyCancele.cancele_free_data"
                    :key="iData"
                  >
                    {{ penalidad(data) }}<br />
                  </span>

                  <br v-if="policyCancele.conditions.length > 0" />
                  <template v-for="(condi, indC) in policyCancele.conditions">
                    <span :key="indC">
                      <span>
                        {{ condi.name }}
                        <ul
                          v-for="(ccc, indCCC) in condi.conditions"
                          :key="indCCC"
                        >
                          <li v-html="ccc.description"></li>
                        </ul>
                      </span>
                    </span>
                  </template>
                </span>
              </template>
              <v-divider
                class="mt-2"
              ></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiSendOutline,
  mdiCurrencyUsd,
  mdiPrinter,
  mdiSquareEditOutline,
  mdiClipboardCheckOutline,
  mdiAccountCashOutline,
  mdiDotsVertical,
  mdiHeartPlusOutline,
  mdiDeleteOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      showModalDelete: false,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiPrinter,
        mdiSquareEditOutline,
        mdiClipboardCheckOutline,
        mdiAccountCashOutline,
        mdiDotsVertical,
        mdiHeartPlusOutline,
        mdiDeleteOutline,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      contrate: {
        operators: [],
        proveedor: {},
      },
      image: [],
      resource: 'local',
      itemsRooms: [],
      nameRooms: '',
      dialog: false,
      policyCancele: null,
      regimenSelect: {
        name: '',
        siglas: '',
      },
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      carItems: state => state.app.carItems,
      loadingBtn: state => state.app.loadingBtn,
      currencySymbolFacturar: state => state.app.currencySymbolFacturar,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
    }),
    checkOnRequest() {
      let onRequest = false
      if (
        this.item.data_service.items.tipo_reserva === 'on-request'
      ) {
        onRequest = true
      }

      return onRequest
    },

    checkInfoPendient() {
      let info = false
      if (!this.item.data_service) {
        info = true
      } else if (!this.item.data_service.ocupation) {
        info = true
      } else {
        this.item.data_service.ocupation.forEach(element => {
          if (!element.paxs[0].name || !element.paxs[0].apellidos) {
            info = true
          }
        })
      }

      return info
    },
    countAdults() {
      let result = 0
      this.item.data_service.ocupation.forEach(element => {
        result += element.cantAdulEdad
      })

      return result
    },
    countChilds() {
      let result = 0
      this.item.data_service.ocupation.forEach(element => {
        result += element.cantTeenEdad
        result += element.cantMnrEdad
        result += element.cantInfEdad

        /* element.edades.forEach(elementEdad => {
          if (elementEdad.edad > 2) {
            result += 1
          }
        }) */
      })

      return result
    },
    countInf() {
      let result = 0
      this.item.data_service.ocupation.forEach(element => {
        element.edades.forEach(elementEdad => {
          if (elementEdad.edad <= 2) {
            result += 1
          }
        })
      })

      return result
    },
  },
  mounted() {
    this.getContrate()
  },
  created() {
    this.getRooms()
    this.loadHotel()
  },

  methods: {
    ...mapMutations(['updateItemToCar', 'emptyClientCarShop', 'updateLoadingBtn', 'deleteItemToCar']),
    loadHotel() {
      if (this.item.data_service.items.hotel.galery === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else if (this.item.data_service.items.hotel.galery_random === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else {
        this.image = this.item.data_service.items.hotel.galery_random
        this.resource = this.item.data_service.items.hotel.resource_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.itemsRooms = res.data.data

            const ids = []
            this.itemsRooms.forEach(element => {
              let cont = 0
              if (!ids.includes(element.id)) {
                this.item.data_service.ocupation.forEach(elementOcup => {
                  if (elementOcup.room_id === element.id) {
                    ids.push(element.id)
                    cont += 1
                  }
                })
              }

              if (cont > 0) {
                if (this.nameRooms === '') {
                  this.nameRooms = `${element.name} x ${cont}`
                } else {
                  this.nameRooms = `${this.nameRooms}, ${element.name} x ${cont}`
                }
              }
            })
          }
        })

      // .finally(() => this.initRoom())
    },
    getContrate() {
      this.axios
        .get(`contrate_hotels/${this.item.data_service.items.contrate_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contrate = res.data.data.data
        })
    },
    deleteItem() {
      this.showModalDelete = true
    },
    confirmDeleteService() {
      this.showModalDelete = false

      /* const cars = localStorage.getItem('car-shop') ? JSON.parse(localStorage.getItem('car-shop')) : []
      cars.splice(this.pos, 1)
      if (cars.length === 0) {
        localStorage.removeItem('car-shop')
        this.$router.push({ name: 'cotizador' })
      }

      localStorage.setItem('car-shop', JSON.stringify(cars))
      this.deleteItemToCar(this.pos) */

      this.axios
        .post('cart/delete', { pos: this.pos }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            // LIMPIAR COTIZADOR
            if (res.data.data.length === 0) {
              this.$router.push({ name: 'cotizador' })
            }
          }
        })
    },
    printInvoice() {
      window.print()
    },
    editServive() {
      const json = {
        pos: this.pos,
        item: this.item,
      }

      localStorage.setItem('car-shop-item', JSON.stringify(json))
      localStorage.setItem('route-redirect', 'car-shop-items')

      this.$router.push({ name: 'car-shop-item-update' })
    },

    changeCheck() {
      localStorage.setItem('car-shop', JSON.stringify(this.carItems))
    },

    showModal(room) {
      this.dialog = true
      room.tarifasSeason.forEach(tar => {
        if (tar.code === room.codeTarifa) {
          this.policyCancele = tar
        }
      })

      if (this.planes.filter(e => e.id === room.plan).length > 0) {
        this.regimenSelect.name = this.planes.filter(e => e.id === room.plan)[0].name
        this.regimenSelect.siglas = this.planes.filter(e => e.id === room.plan)[0].siglas
      }
    },
    penalidad(data) {
      let result = ''
      if (data.antes_de) {
        result += `${this.$t('lbl.antesDe')} ${data.from} ${data.param === 'days' ? this.$t('lbl.day') : data.param}, `
      } else {
        result += `${this.$t('lbl.entre')} ${data.from} ${this.$t('lbl.and')} ${data.to} ${data.param}, `
      }
      result += !data.penalidad_in_pc ? this.currencySymbolFacturar + data.penalidad_valor : data.penalidad_valor

      result += data.penalidad_in_pc ? ' %' : ''
      result += ` ${this.$t('lbl.of')}`
      if (data.all_rooms) {
        result += ` ${this.$t('lbl.roomsAll')}`
      } else {
        result += ` ${this.$tc('lbl.roomsCount', data.cant_rooms)}`
      }
      result += ` ${this.$t('lbl.for')}`
      if (data.all_nights) {
        result += ` ${this.$t('lbl.nightAll')}`
      } else {
        result += ` ${this.$tc('lbl.nightCount', data.cant_nigths)}`
      }

      return result
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
